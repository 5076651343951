<template>
  <!--
    The view for the ContactList-component.
    Used to display all contacts.
  -->
  <Portlet 
    :title="$t('menu.contacts')" 
    icon="address-book" 
    class="contacts"
  >
    <span class="phoneCall">{{ callTitle }}</span>
    <ContactListComplete
      @showCall="showCall"
    />
  </Portlet>
</template>

<script>
export default {
  name: "Contacts",
  components: {
    ContactListComplete: () => import('@/components/CustomerNotification/ContactListComplete.vue')
  },
  data () {
    return {
        callTitle: null
      };
  },
  metaInfo () {
    return {
      title: this.$t('menu.contacts')
    }
  },
  methods: {
    showCall (val) {
      this.callTitle = val;
    }
  }
}
</script>

<style scoped>
 .phoneCall {
   color: red;
   font-weight: bold;
   font-size: 1.3rem;
 }
</style>